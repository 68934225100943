import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

import SubscriptionForm from "../components/forms/subscriptionForm"
import { Header } from "../components/header"
import ComingSoon from "../components/comingSoon"
import { CandyPantsCS } from "../components/candyPants"
import { MiniFooter } from "../components/footer"

const IndexPage = () => (
    <Layout>
        <Seo title="Coming Soon..." />
        <div className="top-gradient-container">
            <Header />
            <ComingSoon />
            <SubscriptionForm />
        </div>
        <CandyPantsCS />
        <MiniFooter />
    </Layout>
)

export default IndexPage
