import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import AnchorTop from "../images/anchor-top.svg"
import AnchorBottom from "../images/anchor-bottom.svg"
import Socials from "./socials"

const ComingSoon = () => (
    <div className="container-fluid container-xxl">
        <div className="row g-0 g-mb-3">
            <div className="col-12 mb-5 col-md-6">
                <div className="row">
                    <div className="col p-0 position-relative">
                        <img src={AnchorTop} className="overlay-anchor-top" alt="Anchor vector" />
                        <StaticImage className="image1" src="../images/image1.png" alt="Girl on yacht" />
                        <StaticImage className="overlay-frame" src="../images/yacht.png" alt="yacht" />
                        <img src={AnchorBottom} className="overlay-anchor-bottom" width="368" alt="Anchor vector" />
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-6 mb-4 left-min-935  d-flex flex-column  justify-content-center">
                <div className="coming-soon-text">
                    <h1 className="mb-3 text-white">Coming Soon...</h1>
                    <p className="sub-title-purple">
                        Immerse yourself in a unique personalized experience and enjoy the stunning scenery of Ibiza from the comfort
                        of your own private yacht on the Mediterranean Sea.
                    </p>
                    <p className="mb-4">
                        Our unrivalled fleet of bespoke yachts will pair together to give you the ultimate holiday experience, offering
                        something for everyone, couples, family days, or just a day with the girls on the sea accompanied by your
                        favourite champagne, wine or beers whilst plugged into our Maliblue music beats and absorbing the breath-taking
                        journey to Formentera. Unbelievable island sunsets or clear blue waters around Es Vedra? We promise you an
                        exhilarating experience, at Maliblue we’ve got you covered!
                    </p>

                    <Socials mail={true} me="me-3" />
                </div>
            </div>
        </div>
    </div>
)

export default ComingSoon
